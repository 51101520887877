@use '@angular/material' as mat;
@include mat.core();

body {
    font-family: 'TeleNeo', sans-serif;
    background-color: #f2f2f2;
}

.spacer-top {
    margin-top: 80px !important;
}

@media (max-width: 1200px) {
    .spacer-top {
        margin-top: 65px !important;
    }
}

.frame {
    min-height: calc(100vh - 136px);
    background-color: #ffffff;
    padding: 32px 0;
    // margin: 32px 6px 24px 6px;
    border-radius: 10px;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
}

.pd24 {
    padding-left: 24px;
    padding-right: 24px;
}

.main {
    background: url('/assets/images/mainbgr2.jpg') no-repeat center 10% fixed;
    background-size: cover;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    position: fixed;
}

.information {
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

canvas.signature-pad-canvas {
    border: none !important;
    background-color: #f9f9f9 !important;
    border-radius: 8px !important;
    opacity: 0.92;
}

* {
    &:active,
    :focus {
        outline: none !important;
    }
}

a:not(.mat-mdc-button):not(.mat-mdc-raised-button):not(.mat-mdc-fab):not(
        .mat-mdc-mini-fab
    ):not([mat-list-item]) {
    color: #000000;
}

.link {
    color: $link-color;
    cursor: pointer;
}

.fix-button-position {
    margin-bottom: 20px !important;
}

/* custom button style */
.actions {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;

    .rounded-button {
        border-radius: 48px;
        background-color: #002c6c;
        width: 24px;
        height: 24px;
        margin-bottom: 4px;
        color: #ffffff;
        cursor: pointer;
    }
}

.has-pointer,
.clickable {
    cursor: pointer !important;
}

.table-badge {
    width: 48px !important;
}

.place {
    width: 36px;
}

.clickable-link {
    color: $link-color !important;
    &:hover {
        color: #000000 !important;
    }
}

.outlined {
    outline: 1px solid #333333 !important;
}

.arrow {
    color: var(--primary-color);
    transition: transform 0.2s ease-in-out;
    transform: rotate(0deg);

    &.open {
        transform: rotate(90deg);
    }
}

.text-primary {
    color: var(--primary-color) !important;
}

.icon-color {
    &-primary {
        color: var(--primary-color);
    }

    &-white {
        color: #ffffff;
    }

    &-success {
        color: #73c354;
    }

    &-alert {
        color: #d82a48;
    }
}

.paginator {
    height: 32px;
}

.space-fill {
    flex: 1;
    width: 100%;
    height: auto;
    border-bottom: 1px solid #f2f2f2;
}

.space-line {
    height: 1px;
    border-top: 1px solid #f2f2f2;
    margin: 12px 0;
}

.supplychain {
    display: flex;
    min-width: 80%;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;

    .workflow-column {
        padding: 8px;
        margin: 48px 12px 0 12px;
        width: 450px;
        display: flex;
        flex-direction: column;
    }

    h3 {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        color: #000;
        margin-left: 12px;
        display: flex;
        align-items: center;

        img {
            margin-right: 6px;
            margin-left: 16px;
        }
    }
}

.rounded-frame {
    border: 1px solid #c4c4c4;
    border-radius: 16px;
    padding: 14px;
    width: 100%;
}

/* headline h1 - h5 */
h5 {
    &.dialog {
        display: flex;
        font-size: 20px !important;
        line-height: 24px !important;
        color: #000000 !important;
        font-weight: 700 !important;
    }
}

@media (max-width: 1200px) {
    .container-fluid {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .frame {
        padding-top: 3px !important;
    }
}

.mat-sort-header {
    padding: 0px !important;
}

@media (max-width: 600px) {
    .mat-mdc-table[matTableResponsive] {
        .mat-mdc-header-row {
            display: flex;
            flex-wrap: wrap;
            height: auto;
            position: sticky;
            top: 0;
            z-index: 2;

            th.mat-mdc-header-cell {
                display: flex;
                flex: 1;
                align-items: center;
                padding: 1rem 0.5rem;
                justify-content: space-around;

                & > .mat-sort-header-container {
                    padding-left: 15px; // padding is used to center the header table cells, due to the width of the sort icon
                }

                &:not([mat-sort-header]),
                &.mat-sort-header-disabled,
                &.mat-column-actions {
                    display: none;
                }
            }
        }

        .mat-mdc-row {
            height: auto;

            td.mat-mdc-cell:not(.mat-column-actions) {
                display: grid;
                grid-template-columns: 100%;
                padding: 0.5rem;
                text-align: center;
                word-break: break-word;

                & > * {
                    grid-column: 1;
                }

                &:before {
                    content: attr(data-column-name);
                    text-align: left;
                }
            }

            td.mat-column-actions {
                display: flex;
                justify-content: center;
                padding: 0 0.5rem;

                & > button {
                    margin: 0.5rem 0;
                }
            }

            &:last-of-type td:last-of-type {
                border: none;
            }

            &:not(:last-of-type) td:last-of-type {
                border-bottom: 0.5rem solid #fafafa;
            }
        }
    }

    .mat-mdc-card,
    .mat-expansion-panel {
        .mat-mdc-table[matTableResponsive] {
            border: 0.5rem solid #fafafa;
        }
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-wrapper {
    height: 100%;
}

.mat-mdc-tab-body {
    min-height: 60vh;
}

.mat-mdc-list-item {
    margin-bottom: 15px;
    border-bottom: 0;
}

/*Tables*/

.dlskey {
    width: 100px;
}

.wfix {
    width: 48px;
}

.highlighted {
    background-color: #d6f8ff;
    border-bottom: 1px solid #ffffff;
}

tr.dls-detail-row {
    height: 0;
}

.dls-element-row td:not(:first-of-type) {
    border-bottom-width: 0 !important;
    padding: 0 7px 0 0;
}

.dls-element-detail {
    overflow: hidden;
    display: block;
}

tr.dls-element-row:not(.dls-expanded-row):hover {
    background-color: #d6f8ff;
}

tr.dls-element-row:not(.dls-expanded-row):active {
    background-color: #ffffff;
}

.dls-element-row td {
    border-bottom-width: 0;
}

td {
    border-left: 0px solid #ccc;
    border-right: 0px solid #ccc;
}

.f-col-1 {
    width: 50px;
}

.f-col-2 {
    width: 210px !important;
    min-width: 210px !important;
}

.f-col-3 {
}

.table {
    th {
        color: #666666 !important;
        font-size: 12px !important;
        font-weight: 400;
    }

    &.maxw {
        width: calc(95vw);
        margin-left: 20px;
    }
}

.loader {
    height: 40px !important;
}

.table-badge {
    @apply tw-relative tw-mr-2 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-200 tw-px-5;
    border: 1px solid #666666;

    &__delete {
        position: absolute;
        bottom: -9px;
        right: -5px;
        color: white;

        background: var(--primary-color);
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    mat-icon {
        width: 16px;
        height: 16px;
        font-size: 16px;
    }
}
