$height_header: 60px;
$margin-site-content: 14px;
$footer_height: 32px;
$selfservice_footer_height: 60px;
$main-color: var(--primary-color);
$primary-color: var(--primary-color) !default;
$primary: var(--primary-color);
$primary-text-color: #000000;
$primary-button-color: #888;
$secondary-text-color: #888;
$inputfield-color: #c4c4c4;
$link-color: var(--primary-color);
$icon-color: var(--primary-color);
$toggle-group-color: rgba(226, 0, 116, 0.1);
$light-background-color: #f2f2f2;
$error-color: #ff0000;
$secondary-color: #000000;
$accent-color: var(--primary-color);
$mat-icon-button: #191919;
$assetsPath: '/assets/images/';
$tsy-logo-white: $assetsPath + 'TSY_Logo_3c_n.svg';
$tsy-logo: $assetsPath + 'TSY_Logo_3c_k.svg';
$tsy-logo-slogan: $assetsPath + 'TSY_Slogan_Basic_3c_n.svg';
