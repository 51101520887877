// Vars
@use './variables/site';

// Tailwind CSS
@tailwind base;
@tailwind components;
@tailwind utilities;

// regular style toast
@import 'ngx-toastr/toastr';

// external
@import 'bootstrap/scss/bootstrap';
@import 'material-symbols/outlined.css';

// Design
@import './scss/fonts';
@import './scss/material';
@import './scss/site';
@import './scss/split-view';
@import './scss/spinner';

// Theme
@import './variables/customized-theme';

:root {
    --gs1-color-color-blue: #002c6c;
    --gs1-color-color-orange: #f26334;
    --gs1-color-color-teal: #22bcb9;
    --gs1-color-ui-1: #f0f5fa;
    --gs1-color-ui-2: #e5f0fc;
    --gs1-color-ui-3: #c4d7ed;
    --gs1-color-ui-4: #e5e8eb;
    --gs1-color-ui-5: #a6b0bb;
    --gs1-color-ui-6: #696d73;
    --gs1-color-ui-7: #414345;
    --gs1-color-ui-8: #262626;

    --primary-color: var(--gs1-color-color-blue);
    --secondary-color: var(--gs1-color-color-orange);
    --gray-light-color: var(--gs1-color-ui-3);
    --gray-dark-color: var(--gs1-color-ui-8);
}

html {
    @apply tw-h-full;
}

body {
    height: calc(100% - 50px);
    @apply tw-overflow-y-scroll;
}

mat-mdc-form-field .mat-mdc-form-field {
    &-underline {
        position: relative;
        bottom: auto;
    }

    &-subscript-wrapper {
        position: static;
    }
}

.divider {
    width: 100%;
    height: 1px;
    margin-top: 20px;
    text-align: center;

    .line {
        border: 1px solid var(--gray-light-color);
        width: 100%;
    }
}

th.cdk-drag {
    cursor: pointer;
}

.top-action-buttons {
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 50;
}

.close-button {
    z-index: 10;

    button {
        border-radius: 0 !important;
        border-bottom-left-radius: 5px !important;
        box-shadow:
            0 5px 5px -3px rgba(0, 0, 0, 0.1),
            0 8px 10px 1px rgba(0, 0, 0, 0.05),
            0 3px 14px 2px rgba(0, 0, 0, 0.03) !important;
    }
}

.create-bundle-button {
    z-index: 10;

    button {
        border-radius: 0 !important;
        border-bottom-left-radius: 5px !important;
        box-shadow:
            0 5px 5px -3px rgba(0, 0, 0, 0.1),
            0 8px 10px 1px rgba(0, 0, 0, 0.05),
            0 3px 14px 2px rgba(0, 0, 0, 0.03) !important;
    }
}

button[mat-raised-button] {
    mat-icon {
        display: inline-flex !important;
        margin-right: 8px;
    }
}

button.mat-mdc-button-base {
    @apply tw-px-4;
}

button.mat-mdc-icon-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

h2.swal2-title {
    margin-bottom: 0;
}

.mat-mdc-dialog-surface {
    justify-content: center;
    display: flex;
    padding: 15px;
}

* {
    touch-action: manipulation;
}

.disable-scroll-and-height {
    overflow-y: visible !important;
    height: auto !important;
}

.custom-tooltip-content {
    max-width: 400px;

    h3 {
        margin: 0;
        margin-top: 5px;
    }
}

.toast-container {
    position: fixed;

    .ngx-toastr {
        background-color: #ffffff;
        box-shadow: 0 0 6px #999999;
        color: black;
        margin-bottom: 10px;
    }

    .ngx-toastr:hover {
        box-shadow: 0 0 6px #999999;
        opacity: 0;
        cursor: pointer;
    }

    span {
        color: black;
    }

    .toast-info {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAoVJREFUaEPtmc1OAjEQx2cE3wMTMfFmIp6FJxFOwlOoTwGewCcRz2LizURM3AdBRrtm47rbdtvOdDck7IUD/fj/OjPttIOw4x/uuH7YAzRtQTELnE3fOu2D9hURdhCoQ0AdBFS/iYJEwIQAE0RKYAtPq0l3KQHPAvgTTUMl1keQAkPExWa7eXidnKaQIV8wQO9+fQMEtyGT5vtkIKvr7l3IWN4Avem6DwiPIZPZ+oSCeAFIrboVHuHWxxrOAL3ZWq16X3rldeMR4OJlfDxymcsJoE7xmWhXiEqAWtzGtNQO7mQFiBWwLq6RtUGi0fPkZGFmtIx2Pnv/9N3ffcS5tFW708v45MgbgOs6q3H3n3V7szW5CPYNaqMLcSZUImQBKPmir4HuxNYCnM8+5gg0DF0xaYBUhyGgDQB835e0gNJvigUtANd9OJaz9iUYFLPYEsDF9H1IiPNoIjgDa9yoBCDh/xyNFX2Xq3F3kG9TAmgibfAArgaQOrykg9gUyDoLBB84+ZXcAxj8qrgwmiDmnwFRDjLDWRAtiGO40M+FyiWI+WlEPAuUb2rRDrIYFtDdDUoA6VsPtj899mZt0xgAG9ocFTNSUy7EvsBLA/glcwKPVvIA+pcKrQUk3EgaQOc+v9cEw8fNSkUBLK8TRgBlhRa2Hpu+1Ou2ZGs2mv+TawXuTpb211xinAFUQ+7rBAuC+7CVTd4QRClt0C1G5dOi6pQVMiTqAY4WcRJv3YV0E9ViCQe38YqBIogK7C3CjfTulBY4CEe+tTMnFypCSLpUaGUm0xQEkHXOgajCh1fxgytcBCBvGQVzCK3+Fg8uq8qs3MokKwYcd5HamrFcqDaVlon2AE1b4Rs3p3NAWtX4qwAAAABJRU5ErkJggg==') !important;
    }

    .toast-error {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAetJREFUaEPtmIttwyAQhn82aTZpR8kkVSdpN2k3aTZJda5tERuOe3BElowiRYoN/B/34pJw8JEOrh8nwLMteFrg0Ba4Ay8APgG8GkFuAN4SQN+mYXahDuIXwS4IE0BH8W4INUCAeBeECiBQvBlCDDBAvAlCBDBQvBqiCfAE8SoIFkAgnlLgB4B3/NcEzZjSJ4BvZm4zxVYBhOKvCfiZ3+WEbMFuCbjQj4K5LAQHQIK4CkvivxZlAiGrayzis7m0D+1XGyvw9gUO4N7wh93JCCB2QgSWnmSk6bMfHgBaTQNhFh8JIIVwiY8GaEGQ7aeA3cSL6hYb5UK5rqI7ba/KUp8vBGtIDOzSI3e/t4of4UKsJbJ8r3KbfNERLlTN8xmAptg9WHcUQLXgeCFGANRSJWWhtecVFLti/YwG4PI8XfIeGncLRCSApEhpKvZQC0jEr8HtsUSEBTTi3RAWgN9Gk3IpBKckz5fcKeQ6TcEn6pYMFXaFmOfSYdUGm5olLWUL4jq3lNq/FwmC5poamYVW2tSbKyhzsq1H7MmLAbwVtKWy8lwkfr7kybawFB/Zyru3xOJVAIMsoRKvBgiGUIs3AQRBmMSbATpDmMW7ADpBuMS7AYxZpuu0ZiHrulvAYidAwKGqljwtoDqugJf/APwY2zH6ltAdAAAAAElFTkSuQmCC') !important;
    }

    .toast-warning {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAgpJREFUaEPtmUFuwjAQRb+lHoTepLlDU6m75iSlJym7LuAO5SblHkVyIQEaEzueGc8EISVbgj1vfv7PGBzu/HJ3Xj9mgFsraKqAX+PpCOhqbK1AbQE2+GkL/0XlXrGzgDAD8Gu8HwpenopeuhofdwPgv7DAw6n756r3eLRQwUSBq+6fEUxUUAeIdt9QBX2ANb6BLn0i19bVqDS9oApwis0jQPryaNwLVloQ2gA+W5jDTjNW1QASxk3xqBlaBWDUuCkEpVjVAdjgEx5v2ccnvEFFhWIAUfcVY7Uc4DjveCyY3T/fvsUeTckbugiAaVwTQ4sBih6dPkphrMoBwmlz0N3D9BmsfVBr7B0hNrQIgNJ9JgAgjFUZwPi806rBBnBYuWc03DBgA5DmHQlAV3nFPX7yAYixyVaglY0/J7EAOLEpAuhUYBmaDEAxbpCOvBQKH32GoekAmdi8Nl+BAiwVSADc7nOTJHo/UQUaACE2VYoOFyEdP7MA1Ng0AAAIx08KQP6YaFI9LVZHATixqWzi/nKjsZoEKDVuYQqRYzUNIDsmXjZWBRh5uUUBSrsvGuZyPkrEahyAOO/k9lT+PHr8HACUGFe54NhyA0OHp6bYz+ITVEXeIjKtXh/7+n9KkNed+MZAhQuAhnEnA+kZ+h/gNvOOjLl3/MyOErIdpvvWDDBdr+M7zQrMChR24A9+ONsxFHIC3gAAAABJRU5ErkJggg==') !important;
    }

    .toast-success {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAsFJREFUaEPtmVtu2zAQRa+Adh/OShp/xwWkFdReSdyVxF2BBNT5TrqSeB9t42oKMVDIITlDjhUEiADDP3zcw3nx0eCdf807148PgLe2oJkF2r5d/QG+nYFVA6xm/yeCbIDTGTjRP4Bfx254tICvApiJ3pJojaAJ5vAJ+DF0w3/Ikq8YYNO3t+OE+5JJ530cyLEbvpeMpQbY9O01gIeSyVJ9SkFUAFarnoHfa6whBtj0La06rf7FvzEBHO67YSeZSASwpHgnWgqRBVjIbWKLnXWnJMClAlbiGq5NA+x+dsMh1icJcNO3T9r8rhHntaXCRr9XqZmy0303XKkBFnadx2M3rEkkN28qHqIWGAc6V6ympuuLeNfJhyArfAbWXMVmAW769m7cs2w1KgrbBuJpnMj8bEDHAJbwfY14xGKBBVjAfVTiZ9Zd+7vYAOBr327PwF2hS0i6lYqnsQM3CgAu7P814gkg6B8ACLYNlKdpT6TdF9WKlwGkiperitNBhtxMCmEhng1kzgJs/vdLugLCRDwtP5eJxABcAAkgzMS77DCeFV5p5oI4VQOCLJCAMBcvtUDu4CKBMBc/WSCfhYRpNAUBtzGbFwXhuMk6wm3qagoZC8FtuCzEExl3NggAJp9+kpRULrD9flbiadzxDunKX6DYXigXB3Od0WOfpXjtZk57aRVAWIqfagB7U8FaQOlGzhovENbiY+4zxQXv7YW70v10qWt9GIq6afRISVb4DTwseKiP5g2/+s4bJm8lCq0gTGDiZsEhRgxADRe+nfCp6i623GhvBMFuR3zC7NUidXAPGVS4xIavaygSn8xC3PwLWSLrNqoY8EEosJ+BW+vsND1w7LRvZyIX8iEsXar0ZcZpKgJwnWcg6kN+rXATgLllCOYvcP0MfMk9s9a+TFbFQF1yse9d5UL2cvQjfgDo18y2xz+c74pARPWMBgAAAABJRU5ErkJggg==') !important;
    }
}

.mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.mdc-tab:not(.mat-mdc-tab-disabled):hover .mdc-tab__text-label {
    color: black;
    font-weight: 700;
}

.custom-row-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.custom-row-border:last-child {
    border-bottom: none !important;
}
