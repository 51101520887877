@import '../variables/site';

.mat-mdc-table {
    width: 100%;

    .mat-mdc-cell {
        font-size: 16px !important;
        color: #000000 !important;
        padding-left: 0px;
    }

    .mat-mdc-cell,
    .mat-mdc-header-cell {
        border-bottom-color: #e6e6e6;
        padding-left: 0px;
    }

    .mat-mdc-header-cell:first-child {
        border-left: 2px solid #ffffff;
    }
    .mat-mdc-header-cell:last-child {
        border-right: 2px solid #ffffff;
    }

    th {
        background-color: #f2f2f2 !important;
        padding-left: 0px;
    }
}

.mat-mdc-paginator {
    display: flex !important;
    justify-content: center !important;

    &-page-size {
        display: none !important;
    }

    &-navigation-first {
        order: 1 !important;
    }

    &-navigation-previous {
        order: 2 !important;
    }

    &-range-label {
        order: 3 !important;
    }

    &-navigation-next {
        order: 4 !important;
    }

    &-navigation-last {
        order: 5 !important;
    }

    &-icon {
        color: var(--primary-color);
    }
}
/*
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button,
.mat-button {
  border-radius: 6px !important;
  font-size: 16px !important;
  // min-width: 150px !important;

  &.big {
    height: 56px !important;
    font-size: 20px !important;
    line-height: 56px !important;
    max-width: 360px !important;
  }

  .mat-icon {
    margin-right: 4px;
  }
}
*/
/*
.mat-raised-button.mat-primary.mat-button-disabled {
  background: rgba(var(--primary-color), 0.5);
  color: #ffffff !important;
}*/

.mat-mdc-unelevated-button,
.mat-mdc-button,
.mat-mdc-raised-button {
    &:disabled {
        .text-primary,
        .icon-color-primary {
            color: #888 !important;
        }
    }
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
.mat-button-toggle-group {
    border-radius: 6px !important;

    &-appearance-standard {
        border-color: var(--primary-color) !important;
        /*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
        .mat-button-toggle + .mat-button-toggle {
            border-color: var(--primary-color) !important;
        }
    }
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
.mat-button-toggle {
    height: 54px;
    font-size: 20px !important;
    line-height: 54px !important;
    min-width: 140px !important;

    &-checked {
        button > span {
            font-weight: 700;
        }
    }

    &-appearance-standard:hover {
        /*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
        .mat-button-toggle-focus-overlay {
            opacity: 0.01 !important;
        }
    }
}

/*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
.mat-form-field-subscript-wrapper {
    font-size: 100% !important;
}

.mat-mdc-checkbox.customized {
    /*
TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
*/
    .mat-checkbox-background,
    .mat-checkbox-frame {
        border-radius: 20px !important;
    }
    /*
TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
*/
    .mat-checkbox-frame {
        border-width: 1px !important;
    }
    /*
TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
*/
    .mat-checkbox-checkmark {
        top: 2px !important;
        left: 2px !important;
        right: 0;
        bottom: 0;
        position: absolute;
        width: 80% !important;
    }
    /*
TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
*/
    .mat-checkbox-ripple {
        display: none;
    }
    /*
TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
*/
    .mat-checkbox-inner-container {
        width: 20px !important;
        height: 20px !important;
    }
}

/*
TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
*/
.mat-checkbox-indeterminate.customized.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.customized.mat-accent .mat-checkbox-background {
    background-color: var(--primary-color) !important;
}

.mat-mdc-icon-button {
    color: $mat-icon-button !important;
}

.mat-mdc-menu-content:not(:empty) {
    min-width: 250px !important;
}

.mat-mdc-menu-item {
    font-size: 16px !important;
    color: #3b3b3b !important;
    line-height: 23px !important;
}

.mat-mdc-menu-panel {
    max-width: fit-content !important;
}

.mat-mdc-tooltip {
    background-color: $link-color !important;
    color: #ffffff !important;
    font-size: 13px !important;
    overflow: visible !important;
}

.mat-mdc-tab {
    &-list {
        border-bottom: 1px solid #f2f2f2 !important;
    }

    &-header {
        border-bottom: 0px !important;
    }

    &-label {
        opacity: 1 !important;

        &-content {
            font-size: 20px !important;
            line-height: 32px !important;
            font-weight: 500 !important;
        }

        &-active {
            color: $primary !important;
        }
    }
}

.mat-mdc-list {
    &.boxed {
        &.mat-mdc-list-base {
            /*max-height: 400px;
            height: 400px;*/
            overflow-y: auto;
        }
    }

    &-item {
        border-bottom: 1px solid rgba(#000000, 0.12);
        font-weight: 700 !important;
        color: #000 !important;

        &-content {
            padding: 0 6px !important;
        }
    }
}

/*
TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.
*/
.mat-mdc-progress-bar .mat-progress-bar-buffer {
    background-color: #999999 !important;
}

/*
TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.
*/
.mat-mdc-progress-bar .mat-progress-bar-fill::after {
    background-color: #216bff !important;
}

.begin {
    /*
TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.
*/
    &.mat-mdc-progress-bar .mat-progress-bar-fill::after {
        background-color: #216bff !important;
    }
}
.failed {
    /*
TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.
*/
    &.mat-mdc-progress-bar .mat-progress-bar-fill::after {
        background-color: #d82a48 !important;
    }
}

.success {
    /*
TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.
*/
    &.mat-mdc-progress-bar .mat-progress-bar-fill::after {
        background-color: #73c354 !important;
    }
}

.failed {
    /*
TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.
*/
    &.mat-mdc-progress-bar .mat-progress-bar-fill::after {
        background-color: #d82a48 !important;
    }
}

/*
TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
.mat-select-arrow {
    position: relative !important;
    top: 6px !important;
}

.mat-expansion-indicator::after {
    color: var(--primary-color) !important;
}

.mat-mdc-menu-content:not(:empty) {
    padding-bottom: 0px !important;
}

/* Dialog Fullscreen on Mobile */
@media screen and (max-width: 900px) {
    .mat-mdc-dialog-container {
        max-width: 100vw !important;
        max-height: 100vh !important;
        height: 100vh !important;
        width: 100vw !important;

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    }

    .cdk-overlay-pane {
        height: 100vh !important;
        width: 100vw !important;
        min-width: unset !important;
    }
}

.mat-mdc-standard-chip {
    background-color: #fff !important;
    color: #000000de !important;
    border: 1px solid #bbb !important;
    border-radius: 5px !important;
    min-height: 38px !important;
    /*
TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.
*/
    .mat-chip-remove {
        color: var(--primary-color) !important;
        opacity: 1 !important;
    }
    &.clickable {
        cursor: pointer !important;
    }
}

/*
TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.
*/
.mat-mdc-standard-chip.mat-chip-with-trailing-icon {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-right: 8px !important;
    padding-left: 12px !important;
}

mat-form-field {
    width: 100%;
}

.mat-mdc-tab-body-wrapper {
    flex-grow: 1;
}
