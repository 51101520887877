.split-view-wrapper {
    height: 100%;
    width: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;

    .left-col {
        float: left;
        width: 49%;
        background-color: var(--primary-color);
        height: 100vh;
        color: #ffffff;

        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: 0 50px;

        .title {
            width: auto;
            white-space: nowrap;
            font-size: 52px;
            font-weight: 900;
            margin-bottom: 30px;
            text-shadow: 0px 3px 6px #00000057;
        }

        .sub-title {
            font-size: 25px;
            width: auto;
            white-space: nowrap;
            font-weight: 400;
            text-shadow: 0px 3px 6px #00000057;
        }

        .logo {
            position: absolute;
            bottom: 80px;
            left: 40px;

            img {
                height: 60px;
            }
        }
    }

    .right-col {
        display: flex;
        width: 51%;
        background-color: #ffffff;
        height: 100%;
        align-items: center;
        justify-content: center;

        .input-container {
            width: 510px;

            h2 {
                color: #000000;
                font-size: 40px;
                font-weight: 900;
                line-height: 48px;
                text-align: center;
            }
            h3 {
                color: #000000;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
            }

            .login-form {
                margin-top: 58px;

                button {
                    margin-top: 5px;
                }
            }
        }
        .service-link {
            font-size: 18px;
            color: $link-color !important;
            text-decoration: none !important;
        }
    }
}

span.tele-icons-outlined {
    margin-right: 12px;
}

.login-btn {
    width: 100%;
    height: 56px !important;
    font-size: 20px !important;
    line-height: 56px !important;
}

@media screen and (max-width: 1920px) {
}
@media screen and (max-width: 1440px) {
}
@media (max-width: 1280px) {
    .split-view-wrapper {
        .left-col {
            .logo {
                position: absolute;
                bottom: 60px;
                left: 60px;

                img {
                    height: 52xpx;
                }
            }

            .title {
                font-size: 42px;
            }

            .sub-title {
                font-size: 20px;
            }
        }
        .right-col {
            display: inline-block;
            width: 51%;
            background-color: #ffffff;
            height: 100vh;

            .input-container {
                width: 400px;

                h2 {
                    color: #000000;
                    font-size: 34px;
                    font-weight: 900;
                    line-height: 40px;
                    text-align: center;
                }

                .login-form {
                    margin-top: 58px;

                    button {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .split-view-wrapper {
        .left-col {
            width: 52%;

            .logo {
                position: absolute;
                bottom: 50px;
                left: 60px;

                img {
                    height: 38px;
                }
            }

            .title {
                font-size: 28px;
            }

            .sub-title {
                font-size: 17px;
            }
        }

        .right-col {
            width: 48%;
            display: inline-block;
            background-color: #ffffff;
            height: 100vh;

            .input-container {
                width: 348px;

                h2 {
                    color: #000000;
                    font-size: 22px;
                    font-weight: 900;
                    line-height: 28px;
                    text-align: center;
                }

                .login-form {
                    button {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
}
@media screen and (max-width: 420px) {
    .split-view-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        background-color: #ffffff !important;
        height: 100vh;

        .right-col {
            flex: 1;
            float: none;
            height: 100%;
            width: 100%;
            .input-container {
                width: 90%;

                h2 {
                    color: #000000;
                    font-size: 22px;
                    font-weight: 900;
                    line-height: 28px;
                    text-align: center;
                }

                .login-form {
                    margin-top: 20px;

                    button {
                        margin-top: 5px;
                    }
                }
            }
        }
        .left-col {
            float: none;
            height: 33vh;
            width: 100%;
            .logo {
                top: 0;
                left: 0;
                bottom: auto;
                margin-top: 190px;
                margin-left: 32px;

                img {
                    height: 38px;
                }
            }

            .title {
                font-size: 28px;
            }

            .sub-title {
                font-size: 17px;
            }
        }
    }
}
