/* Teleneo font settings */
@font-face {
    font-family: 'TeleNeo';
    src: url('../../assets/fonts/Teleneo/TeleNeoWeb-Thin.woff2') format('woff2'),
        url('../../assets/fonts/Teleneo/TeleNeoWeb-Thin.woff') format('woff'),
        url('../../assets/fonts/Teleneo/TeleNeoOffice-Thin.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'TeleNeo';
    src: url('../../assets/fonts/Teleneo/TeleNeoWeb-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Teleneo/TeleNeoWeb-Regular.woff') format('woff'),
        url('../../assets/fonts/Teleneo/TeleNeoOffice-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'TeleNeo';
    src: url('../../assets/fonts/Teleneo/TeleNeoWeb-Medium.woff2') format('woff2'),
        url('../../assets/fonts/Teleneo/TeleNeoWeb-Medium.woff') format('woff'),
        url('../../assets/fonts/Teleneo/TeleNeoOffice-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'TeleNeo';
    src: url('../../assets/fonts/Teleneo/TeleNeoWeb-Bold.woff2') format('woff2'),
        url('../../assets/fonts/Teleneo/TeleNeoWeb-Bold.woff') format('woff'),
        url('../../assets/fonts/Teleneo/TeleNeoOffice-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'TeleNeo';
    src: url('../../assets/fonts/Teleneo/TeleNeoWeb-ExtraBold.woff2') format('woff2'),
        url('../../assets/fonts/Teleneo/TeleNeoWeb-ExtraBold.woff') format('woff'),
        url('../../assets/fonts/Teleneo/TeleNeoOffice-ExtraBold.ttf') format('truetype');
    font-weight: 900;
}

/* TeleIconFont */

@font-face {
    font-family: 'TeleIcons';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/TeleIconFont/TeleIcon-Solid.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'TeleIcons Outlined';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/TeleIconFont/TeleIcon-Outline.ttf') format('truetype');
    font-display: swap;
}

.tele-icons {
    font-family: 'TeleIcons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
}

.tele-icons-outlined {
    font-family: 'TeleIcons Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
}

.font {
    &-size-16 {
        font-size: 16px;
        line-height: 20px;
    }

    &-size-20 {
        font-size: 20px;
        line-height: 24px;
    }

    &-size-24 {
        font-size: 24px;
        line-height: 30px;
    }

    &-weight-light {
        font-weight: 300;
    }

    &-weight-regular {
        font-weight: 400;
    }

    &-weight-medium {
        font-weight: 500;
    }

    &-weight-bold {
        font-weight: 700;
    }

    &-weight-heavy {
        font-weight: 900;
    }
}
